import api from '@/plugins/api'
import { profile as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_SESSION } from '@/configs/constants'

const store = 'profile'

export default {
  state: () => ({
    user: {},
    permissions: [],
    sessions: [],
    isLoadSessions: false
  }),
  actions: {
    async getProfile (context) {
      const response = await api.get(endpoint.me)
      const responsePermissions = await api.get(endpoint.permissions)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'user', data: response.data })
        context.commit('SET_DATA', { store, state: 'permissions', data: responsePermissions.data.permissions })
      }
    },

    async getSessions (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadSessions', data: true })

      params.page_size = PAGE_SIZE_SESSION
      params.is_api_key = false
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.sessions() }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'sessions', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadSessions', data: false })
      return response.data
    },
    async deleteToken (context, id) {
      const response = await api.delete(endpoint.sessions(id))
      return await requestValidation(response)
    },

    async changePassword (context, body) {
      const response = await api.post(endpoint.changePassword, { body })
      return await requestValidation(response)
    },

    async saveFilter (context, body) {
      const response = await api.post(endpoint.savedFilters(), { body })
      return await requestValidation(response)
    },

    async getFilters (context, params = {}) {

      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.savedFilters() }${ getParams ? `?${ getParams }` : '' } `)
      await requestValidation(response)
      return response.data
    },

    async deleteFilter (context, id) {
      const response = await api.delete(endpoint.savedFilters(id))
      return await requestValidation(response)
    }
  }
}
