import api from '@/plugins/api'
import { license } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

// const store = 'license'

export default {
  state: () => ({}),
  actions: {
    async getLicense ({ commit, rootState }) {
      const response = await api.get(license.license)
      const locationDefault = rootState.app.location
      const locationKeys = response.data?.user_address?.split(',')
      if (!locationKeys) {
        return
      }
      const location = {}
      Object.keys(locationDefault).forEach((key, index) => {
        if (locationKeys[index])
          location[key] = locationKeys[index]
      })
      commit('SET_DATA', { store: 'app', state: 'location', data: location })

      const isSuccess = await requestValidation(response)
      if (isSuccess) return response.data
      return {}
    }
  }
}
