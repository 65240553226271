import api from '@/plugins/api'
import { user as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_USER } from '@/configs/constants'

const store = 'user'

export default {
  state: () => ({
    users: [],
    userInfo: {},
    isLoadingUsers: false,
    isLoadingInfoUser: false
  }),
  actions: {
    async getUsers (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadingUsers', data: true })

      params.page_size = PAGE_SIZE_USER
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.user }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'users', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingUsers', data: false })
    },

    async getInfoUser (context, id) {
      const isExistData = !!Object.keys(context.state.userInfo).length
      if (!isExistData) {
        context.commit('SET_DATA', { store, state: 'isLoadingInfoUser', data: true })

        const response = await api.get(endpoint.action(id))
        const isResult = await requestValidation(response)

        if (isResult) {
          context.commit('SET_DATA', { store, state: 'userInfo', data: response.data })
          context.commit('SET_DATA', { store, state: 'isLoadingInfoUser', data: false })
        }
        return response.code
      } else return true
    },

    async postUser (context, body) {
      const response = await api.post(endpoint.user, { body: body })
      return await requestValidation(response)
    },

    async patchUser (context, body) {
      const response = await api.patch(endpoint.action(body.id), { body: body })
      const isResult = await await requestValidation(response)
      if (isResult) {
        context.commit('SET_DATA', { store, state: 'userInfo', data: response.data })
      }
      return isResult
    },
    async deleteUser (context, id) {
      const response = await api.delete(endpoint.action(id))
      return await requestValidation(response)
    }
  },
  getters: {
    userById: state => id => state.users.find(item => Number(item.id) === Number(id))
  }
}
