import api from '@/plugins/api'
import notify from '@/plugins/notify'
import { core as endpoints } from '@/configs/endpoints'

export default {
  state: () => ({
    isLoad: true,
    isMini: false,
    isMobile: false,
    version: null,
    location: {
      country: 'Ukraine',
      city: 'Zaporizhzhia',
      street: '',
      house: ''
    }
  }),
  actions: {
    async getVersion (context) {
      const response = await api.get(endpoints.version)
      context.commit('SET_DATA', { store: 'app', state: 'version', data: response.data.epv2_version })
    },

    async getLatestRelease (context) {
      const response = await api.get(endpoints.latestRelease)
      context.commit('SET_DATA', { store: 'app', state: 'latestRelease', data: response.data })

      if (response.data.ep_is_outdated) {
        const link = 'https://docs.easypon.in/how-to-install-easypon'
        const onClosing = () => localStorage.setItem('isOutdatedRelease', response.data.ep_is_outdated)

        notify.success(
          'notify.updateVersion',
          { link },
          true,
          onClosing
        )
      }
    },

    async getGoogleClientId () {
      return await api.get(endpoints.googleClientId)
    }
  },
  getters: {}
}
