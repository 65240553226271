<template lang="pug">
  div
    div(v-if="isLoadingApp")
      Loader
    div(v-else)
      v-app(v-if="isGuestRoute").ep
        div(:class="isLoginRoute && 'login-background'").d-flex.flex-grow-1.justify-center.public-route.px-4
          router-view
      v-app(v-else).ep
        Navigation
        div(:class="isMini ? 'v-main-mini' : 'v-main'")
          router-view
</template>

<script>
import Navigation from '@/components/Navigation'
import Loader from '@/views/Loader'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Navigation,
    Loader
  },
  data () {
    return {
      breakpoint: 960,
      isGuestRoute: true,
      isLoginRoute: false
    }
  },
  computed: {
    ...mapState({
      isLoadingApp: state => state.app.isLoad,
      isMini: state => state.app.isMini
    }),
    isAuth () {
      return this.$auth.isAuth()
    }
  },
  watch: {
    '$route' (route, from) {
      if (from.meta.public || from.name === null) {
        this.SET_DATA({ store: 'app', state: 'isLoad', data: false })
      }
      this.isGuestRoute = route.meta.public || false
      this.isLoginRoute = route.name === 'login' || false
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)
    this.SET_DATA({ store: 'app', state: 'isMobile', data: window.innerWidth < this.breakpoint })
    if (this.isAuth) {
      await this.loadDataOnLogin()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations(['SET_DATA']),
    ...mapActions(['loadDataOnLogin']),
    handleResize () {
      this.SET_DATA({ store: 'app', state: 'isMobile', data: window.innerWidth < this.breakpoint })
    }
  }
}
</script>
