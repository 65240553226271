<template lang="pug">
  include ../../plugins/pug/template
  div(:id="anchor").anchor-offset
    +select({
      model: 'sortedSelect',
      items: 'sortedItems',
      label: 'sortBy',
      itemText: 'text',
      itemValue: 'value',
      isMultiple: 'false'
    })(
      v-if="isMobile"
      clearable
      chips
      return-object
      @change="onSortSelect"
    )
    v-data-table(
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="isLoad"
      :options.sync="options"
      :serverItemsLength="serverItemsLength"
      loading-text="Loading..."
      no-results-text="Data not found"
      no-data-text="No data"
      hide-default-footer
      :mobile-breakpoint="mobileBreakpoint"
      :class="customClass"
      :disable-sort="isMobile"
      @click:row="handleRowClick"
      @update:sort-desc="updatePage(1, options)"
    )
      template(#item.copyToClipboard="{item, header, index}")
        div.d-flex.align-center.cursor-pointer(@click="copyToClipboard(item[header.target], index)")
          template(v-if="copied !== index ")
            span {{ item[header.target].length > 8 ? `${ item[header.target].slice(0, 5) }...${ item[header.target].slice(-5) }` : item[header.target] }}
            img(src="@/assets/icons/files-dark.svg").ml-2.copy-img
          span(v-else) {{ $t('copied') }}
      template(#item.delete="{ item }")
        img(src="@/assets/icons/delete-red.svg" @click="$emit('delete', item)").cursor-pointer
      template(#item.customLink="{ item }")
        router-link(
          v-if="item.customLink.value"
          :to="item.customLink.value"
        ).custom-link {{ item.customLink.title }}
        span(v-else) {{ item.customLink.title }}

      template(#item.index="{ index }")
        span {{ index + 1 + items.length * (currentPage - 1) }}

      template(#item.created_at="{ item }")
        span.white-space-nowrap {{ convertTime(item.created_at) }}

      template(#item.found_at="{ item }")
        span.white-space-nowrap {{ convertTime(item.found_at, 'HH:mm') }}

      template(#item.started_at="{ item }")
        span.white-space-nowrap.b-600 {{ convertTime(item.started_at) }}

      template(#item.oltName="{ item }")
        span.white-space-nowrap.b-600 {{ item.olt?.name || '-' }}

      template(#item.portNumber="{ item }")
        span.white-space-nowrap.b-600 {{ item.port || '-' }}

      template(#item.expiry="{ item }")
        span.white-space-nowrap {{ convertTimeExpire(item.expiry) }}

      template(#item.last_login="{ item }")
        span.white-space-nowrap {{ item.last_login ? convertTime(item.last_login) : '-' }}

      template(#item.last_activity="{ item }")
        span.white-space-nowrap {{ convertTime(item.last_activity) }}

      template(#item.last_run_at="{ item }")
        span.white-space-nowrap {{ item.last_run_at ? convertTime(item.last_run_at) : '-' }}

      template(#item.date_done="{ item }")
        span.white-space-nowrap {{ convertTime(item.date_done) }}

      template(#item.is_current_session="{ item }")
        span {{ item.is_current_session ? $t('yes') : $t('no') }}

      template(#item.description="{ item }")
        div.report-description {{ item.description }}

      template(#item.mac="{ item }")
        span.infoMacAddressTable-mac {{ item.mac }}

      template(#item.slotInfo="{ item }")
        span {{ item.slot.normalized_config_type.normalized_config_type }}
        span {{ ', ' + $t('table.ports') + ': ' + item.slot.normalized_config_type.port_count }}
        span {{ ', ' + item.slot.normalized_config_type.card_type }}

      template(#item.autosave="{ item }")
        span(:class="statuses.activity(item.autosave).textColorShort") {{ statuses.activity(item.autosave).shortName }}

      template(#item.enabled="{ item }")
        span {{ item.enabled ? $t('yes') : $t('no') }}

      template(#item.group="{ item }")
        span {{ item.group || $t('no') }}

      template(#item.typeVlan="{ item }")
        span {{ $t(item.type) }}

      template(#item.qinq_vlan="{ item }")
        span {{ item.qinq_vlan ? qinqVlanInfo(item.qinq_vlan) : $t('statuses.inactive') }}

      template(#item.port="{ item, header }")
        span {{ item[header.keyValue]?.olt?.name }} - {{ item[header.keyValue]?.port_tree }}

      template(#item.vlans="{ item }")
        span {{ item.vlansObj.tag.length ? 'Tag: ' + item.vlansObj.tag : '' }}
        br(v-if="item.vlansObj.tag.length && item.vlansObj.untag.length")
        span {{ item.vlansObj.untag.length ? 'Untag: ' + item.vlansObj.untag : '' }}

      template(#item.status_task="{ item }")
        span {{ item.status }}

      template(#item.is_alive="{ item }")
        span(:class="getColorAliveScheme(item)" v-text="item.is_alive ? $t('statuses.online') : $t('statuses.offline')" )

      template(#item.chipStatus="{ item, header }")
        v-chip(
          :color="getStatusInfo(item, header).background"
          small
          v-on="header.actionName ? { click: ()=>emitStatusAction(item, header.actionName) } : {}"
        ).onu-chip
          v-icon(:class="getStatusInfo(item, header).color").mr-1 mdi-circle-medium
          span(:class="getStatusInfo(item, header).textColor") {{ getStatusInfo(item, header).name }}

      template(#item.admin_status_port="{ item }")
        span(
          :class="statuses.adminPort(item.admin_status).textColor"
          @click="$emit('changeAdminStatusPort', item)"
        ).text-uppercase.cursor-pointer
          | {{ statuses.adminPort(item.admin_status).name }}
      template(#item.oper_status="{ item }")
        span {{ statuses.operation(item.oper_status).text }}

      template(#item.operStatusSwitch="{ item }")
        span {{ statuses.operationSwitch(item.oper_status).text }}

      template(#item.type_device="{ item }")
        span {{ $t(`typeDevice.${item.type_device}`) }}

      template(#item.speed="{ item }")
        span {{ statuses.speed(item.speed).text }}

      template(#item.olt_type="{ item }")
        span {{ directoryById('typeOlt', item?.olt_type)?.value }}

      template(#item.onu_measurements.onu_rx="{ item }")
        span {{ getFloorNumberMeasurements(item.onu_measurements, 'onu_rx') }}

      template(#item.onu_measurements.onu_tx="{ item }")
        span {{ getFloorNumberMeasurements(item.onu_measurements, 'onu_tx') }}

      template(#item.olt_measurements="{ item }")
        span {{ getFloorNumberProperty(item, 'olt_measurements') }}

      template(#item.onu_measurements="{ item }")
        span {{ getFloorNumberProperty(item, 'onu_measurements') }}

      template(#item.attenuation_measurements="{ item }")
        span {{ getFloorNumberProperty(item, 'attenuation_measurements') }}

      template(#item.portErrors="{ item }")
        div {{ $t('uplink') }}: {{ item.count_uplink_errors }}
        div {{ $t('downlink') }}: {{ item.count_downlink_errors }}

      template(#item.portTraffic="{ item }")
        div {{ $t('uplink') }}: {{ item.count_uplink_traffic }}
        div {{ $t('downlink') }}: {{ item.count_downlink_traffic }}

      template(#item.portLoad="{ item, header }")
        div.d-flex.align-center
          span.w-54 {{ `${ item.registered_onus }/${ header.onuAmount }` }}
          span -
          span(:class="getProgressClass(portLoad(item, header.onuAmount))").pl-1 {{ portLoad(item, header.onuAmount) }}%

      template(#item.showConfig="{ item }")
        +btn-icon('primary', 'tablet-dark.svg')(
          @click="$emit('show-config', item)"
        )

      template(#item.download="{ item }")
        v-progress-circular(
          v-if="!item.file_exists"
          :size="20"
          width="2"
          color="#667085"
          indeterminate
        ).loader.ml-3
        +btn-icon('primary', 'download-cloud.svg')(
          v-else
          @click="$emit('on-download', item)"
          :ripple="false"
        )

      template(#item.migrations="{ item, header }")
        div.d-flex.align-center
          +btn-icon('primary', 'row-right-primary.svg')(
            v-if="item.active_migration"
            :to="{ name: 'olt-migration-info', params: { id: item.active_migration } }"
          )
          +btn-icon('primary', 'add-dark.svg')(
            v-else
            @click="$emit('open-migration-info', item)"
          )

      template(#item.mac_address="{ item }" v-if="selectMacOrSn")
        div(
          v-if="typeof item.mac_address === 'string'"
          :class="{ 'data-info-mac-or-sn custom-class': isQueryMacAddress(item.mac_address) }"
        )
          span {{ item.mac_address }}

        div(
          v-else
          :key="i"
          v-for="(mac, i) in item.mac_address"
          :class="{ 'data-info-mac-or-sn custom-class': isQueryMacAddress(mac) }"
        )
          span {{ mac }}
        template(v-else)
          template(v-if="!hasMacArrayQueryMacAddress(item.mac_address)")
            div(
              :key="i"
              v-for="(mac, i) in item.mac_address.slice(0, 5)"
            )
              span {{ mac }}
            template(v-if="item.mac_address.length > 5" )
              v-dialog(width="600")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(
                    v-bind="attrs"
                    v-on="on"
                  ).view-ONU-macs-btn.my-2
                    span.title-custom {{ $t('viewAllOnuMacs') }}
                    img(:src="ArrowDarkTopRight").view-ONU-macs-btn--icon
                template(v-slot:default="dialog")
                  v-card.text-center
                    v-card-title
                      div.mr-4 {{ $t('listOfMacs') }}
                      +btn-icon('primary', 'close-dark.svg')(@click='dialog.value = false').close-modal-button
                    v-card-text.overflow-auto.h-500
                      div(
                        v-for="(mac, index) of item.mac_address"
                        :key="`modal-mac-address-${index}`"
                      ) {{ mac }}

          template(v-else)
            div(
              :key="i"
              v-for="(mac, i) in getMacArrayQueryMacFirst(item.mac_address).slice(0, 5)"
            )
              span(:class="{ 'data-info-mac-or-sn custom-class': i === 0 }") {{ mac }}
            template(v-if="item.mac_address.length > 5")
              v-dialog(width="600")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(
                    v-bind="attrs"
                    v-on="on"
                  ).view-ONU-macs-btn.my-2
                    span.title-custom {{ $t('viewAllOnuMacs') }}
                    img(:src="ArrowDarkTopRight").view-ONU-macs-btn--icon
                template(v-slot:default="dialog")
                  v-card.text-center
                    v-card-title
                      div.mr-4 {{ $t('listOfMacs') }}
                      +btn-icon('primary', 'close-dark.svg')(@click='dialog.value = false').close-modal-button
                    v-card-text.overflow-auto.h-500
                      div(
                        v-for="(macItem, index) of item.mac_address"
                        :key="`modal-mac-address-${index}`"
                      )
                        span(
                          :class="{ 'data-info-mac-or-sn custom-class': isQueryMacAddress(macItem) }"
                        ) {{ macItem }}

      template(#item.mac_address="{ item }" v-else)
        div(
          v-if="typeof item.mac_address === 'string'"
        ) {{ item.mac_address }}

        template(v-else)
          div(
            :key="i"
            v-for="(mac, i) in item.mac_address.slice(0, 5)"
          )
            span {{ mac }}
          template(v-if="item.mac_address.length > 5" )
            v-dialog(width="600")
              template(v-slot:activator="{ on, attrs }")
                div(
                  v-bind="attrs"
                  v-on="on"
                ).my-2
                  span().title-custom.view-ONU-macs-btn.custom-link {{ $t('viewAllOnuMacs') }}
              template(v-slot:default="dialog")
                v-card.text-center
                  v-card-title
                    div.mr-4 {{ $t('listOfMacs') }}
                    +btn-icon('primary', 'close-dark.svg')(@click='dialog.value = false').close-modal-button
                  v-card-text.overflow-auto.h-500
                    div(
                      v-for="(mac, index) of item.mac_address"
                      :key="`modal-mac-address-${index}`"
                    ) {{ mac }}

      template(#item.add="{ item }")
        v-btn(icon @click="$emit('add', item)")
          img(:src="AddDark")

      template(#item.delete_user="{ item }")
        v-btn(icon @click="$emit('delete', item)" :disabled="item.is_current_session")
          img(:src="DeletePrimary")

      template(#item.delete_onu="{ item }")
        v-btn(v-if="!item.isDeleting" icon @click="$emit('delete', item)")
          img(:src="DeletePrimary")
        v-progress-circular(
          v-else
          :size="20"
          width="2"
          color="#667085"
          indeterminate
        ).loader.ml-3
      template(#item.edit="{ item }")
        v-btn(icon @click="$emit('edit', item)")
          img(:src="EditDark")

      template(#item.itemInfo="{ item }")
        v-btn(@click="$emit('transition', item)" icon)
          img(:src="RowRightDark")

      template(#item.itemLink="{ item }")
        v-btn(:to="{ name: btnLink.name, params: {...getLinkParam(btnLink, item)} }" exact icon)
          img(:src="RowRightDark")

      template(#item.onuList="{ item }")
        v-btn(:to="{ name: btnLink.name, params: {...getLinkParamOnuPosition(item)} }" exact icon)
          img(:src="RowRightDark")

      template(#item.getOnu="{ item }")
        SyncOnu(
          :slotNumber="item.slot_number"
          :port="item.port_number"
          isView
        ).px-0

      template(#item.viewTaskInfo="{ item }")
        SyncOnu(
          :taskIdExist="item.task_id"
          isViewTaskInfo
          isTable
        )

      template(#item.syncOnuDuplicate="{ item }")
        SyncOnuDuplicateTable(:onuDuplicateId="item.id").ml-auto.mr-auto

      template(#item.last_online="{ item }")
        span {{ formatTime(item.last_online) }}

      template(v-for="slot in Object.keys($slots)" v-slot:[slot]="{ item }")
        slot(:name="slot")
    v-pagination(
      v-if="pageCount >= 2"
      v-model="currentPage"
      :length="pageCount"
      :total-visible="isMobile ? 5 : 7"
      :previous-aria-label="$t('previous')"
      :next-aria-label="$t('next')"
      aria-label="table-pagination"
      prev-icon="$prevArrow"
      next-icon="$nextArrow"
    ).mt-6
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { convertTime, getSortingParam, convertTimeExpire } from '@/mixins/main'
import statuses from '@/configs/statuses'

import AddDark from '@/assets/icons/add-dark.svg'
import DeletePrimary from '@/assets/icons/delete-primary.svg'
import ArrowDarkTopRight from '@/assets/icons/arrow-dark-top-right.svg'
import RowRightDark from '@/assets/icons/row-right-dark.svg'
import EditDark from '@/assets/icons/edit-dark.svg'

export default {
  components: {
    SyncOnu: ()  => import('@/views/Olt/Info/SyncOnu'),
    SyncOnuDuplicateTable: ()  => import('@/views/OnuDuplicate/SyncOnuDuplicateTable')
  },
  props: {
    mobileBreakpoint: {
      type: Number,
      default: 960
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: -1
    },
    pageCount: {
      type: Number,
      default: 0
    },
    serverItemsLength: {
      type: Number,
      default: -1
    },
    btnLink: {
      type: Object,
      default: null
    },
    detailedRoute: {
      type: String,
      default: null
    },
    changePage: {
      type: Function,
      default: null
    },
    tablePrefix: {
      type: String,
      default: ''
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    actionIcon: {
      type: String,
      default: null
    },
    actionRowClick: {
      type: Function,
      default: null
    }
  },
  data () {
    const currentPage = Number(this.$route.query[`${ this.tablePrefix ? `${ this.tablePrefix }_` : '' }page`]) || 1

    const options = {
      sortBy: [],
      sortDesc: []
    }
    if (this.$route.query.ordering) {
      const order = this.$route.query.ordering
      const direction = order[0] !== '-'
      const item = direction ? order.replace('-', '') : order
      options.sortBy = [item]
      options.sortDesc = [direction]
    }
    const sortedSelect = this.$route.query.ordering || null
    return {
      sortedSelect,
      statuses,
      currentPage,
      selectedIndex: null,
      isViewMacAddressesModal: false,
      copied: null,
      options,
      AddDark,
      DeletePrimary,
      EditDark,
      RowRightDark,
      ArrowDarkTopRight,
      convertTime,
      convertTimeExpire
    }
  },
  computed: {
    sortedItems(){
      return this.headers.reduce(
        (acc, item) => {
          if (item.sortable !== false) {
            acc.push({
              value: item.value,
              text: `${ item.text } ${ this.$t('asc') }`,
              sortBy: [item.value],
              sortDesc: [false]
            })
            acc.push({
              value: `-${ item.value }`,
              text: `${ item.text } ${ this.$t('desc') }`,
              sortBy: [item.value],
              sortDesc: [true]
            })
          }
          return acc
        },
        []
      )
    },
    anchor () {
      if (this.tablePrefix) {
        return null
      }
      return 'table-main'
    },
    routePrefix () {
      return this.tablePrefix ? `${ this.tablePrefix }_` : ''
    },
    ...mapState({
      isMobile: state => state.app.isMobile
    }),
    ...mapGetters(['directoryById', 'oltById']),
    customClass () {
      const lastValue = this.headers.slice(-1)[0].value
      return ['itemLink', 'onuList'].includes(lastValue) ? ['table-with-custom-redirect-btn'] : []
    },
    selectMacOrSn () {
      const selectMacOrSn = this.$route.query?.[`${ this.routePrefix }selectMacOrSn`]
      if (selectMacOrSn) return selectMacOrSn
      return false
    }
  },
  watch: {
    '$route.query' (next, prev) {
      const pageKey = `${ this.routePrefix }page`
      if (next[pageKey] && next[pageKey] !== prev[pageKey]) {
        const page = Number(next[pageKey])
        if (page !== Number(this.currentPage)) this.currentPage = page
      }
    },
    currentPage (val) {
      this.updatePage(val)
    }
  },
  methods: {
    onSortSelect() {
      let sortBy = []
      let sortDesc = []
      if (this.sortedSelect) {
        sortBy = this.sortedSelect.sortBy
        sortDesc = this.sortedSelect.sortDesc
      }
      this.options = { ...this.options, sortBy, sortDesc }
      this.updatePage(1, this.options)
    },
    formatTime (item) {
      return convertTime(item)
    },
    qinqVlanInfo (item) {
      return `${ item.svlan }, ${ item.start }, ${ item.end }`
    },
    portLoad (item, onuAmount) {
      const result = (item.registered_onus / onuAmount * 100).toFixed(2)
      return result === '0.00' ? 0 : result
    },
    getProgressClass (result) {
      const amount = Number(result)
      switch (true) {
        case amount < 31:
          return 'success--text'
        case amount >= 31 && amount < 61:
          return 'info--text'
        case amount >= 61 && amount < 81:
          return 'warning--text'
        case amount >= 81:
          return 'error--text'
      }
    },
    emitStatusAction (item, actionName) {
      actionName && this.$emit(actionName, item)
    },
    getStatusInfo (item, header) {
      let value = item[header.valueKey]
      if (header.valueKey.includes('.')) {
        value = item
        const keys = header.valueKey.split('.')
        keys.forEach(key => value = value[key])
      }
      return this.statuses[header.constantKey](value) || {}
    },
    handleRowClick (e) {
      if (typeof(this.actionRowClick) === "function") this.actionRowClick(e)
    },
    getMacArrayQueryMacFirst(macArray = []) {
      const indexQueryMac = macArray.findIndex(el => this.isQueryMacAddress(el))
      const newArray = macArray.filter(el => !this.isQueryMacAddress(el))

      newArray.unshift(macArray[indexQueryMac])
      return newArray
    },
    hasMacArrayQueryMacAddress (macArray = []) {
      return macArray.some(element => {
        return this.isQueryMacAddress(element)
      })
    },
    isQueryMacAddress (macAddress) {
      return macAddress.replaceAll(':', '').replaceAll('.', '') === this.selectMacOrSn
    },
    getFloorNumberProperty (measurementsOnu, propertyName) {
      const commaIndex = measurementsOnu[propertyName].indexOf('.')
      if (commaIndex === -1) return measurementsOnu[propertyName]
      return measurementsOnu[propertyName].slice(0, commaIndex + 3)
    },
    getFloorNumberMeasurements (measurementsOnu = null, propertyName) {
      if (!measurementsOnu) return ''
      if (measurementsOnu[propertyName].length === 0) return ''
      return this.getFloorNumberProperty(measurementsOnu, propertyName)
    },
    getColorAliveScheme(item) {
      if (item.is_active && !item.is_alive) return 'text-error'
      if (!item.is_active && !item.is_alive) return 'text-warning'
      if (item.is_active && item.is_alive) return 'text-success'
      else return ''
    },
    getLinkParamOnuPosition (item) {
      const hasOnuInItem = 'onu' in item
      if (hasOnuInItem) {
        const position = item.onu.onu_position.replace(':', '/').split('/')
        return {
          olt_id: item.onu.olt.id,
          slot_number: position[1],
          port_number: position[2],
          onu_number: position[3]
        }
      } else {
        const position = item.onu_position.replace(':', '/').split('/')
        return {
          olt_id: item.oltId,
          slot_number: position[1],
          port_number: position[2],
          onu_number: position[3]
        }
      }
    },
    getLinkParam (btnLink, item) {
      const paramLink = {}
      paramLink[btnLink.field] = item[btnLink.field]
      return paramLink
    },
    updatePage (val, options = undefined) {
      const query = Object.assign({}, this.$route.query)
      let ordering = getSortingParam(options)

      ordering?.forEach((_item, key) => {
        const direction = _item[0] === '-' ? '-' : ''
        const item = direction ? _item.replace('-', '') : _item
        ordering[key] = direction + (this.headers.find(target => target.value === item).sortBy  || item)
      })

      this.currentPage = val
      query[`${ this.routePrefix }page`] = String(val)
      if (ordering) query[`${ this.routePrefix }ordering`] = ordering

      const selectDirection = this.options.sortDesc[0] ? '-' : ''
      this.sortedSelect = `${ selectDirection }${ this.options.sortBy[0] }`

      this.$router.replace({ query, hash: this.anchor }, () => {})
      if (this.changePage) this.changePage()
    },
    copyToClipboard (text, index) {
      try {
        const el = document.createElement('textarea')
        el.value = text
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.copied = index
      } catch (e) {
      }
      setTimeout(() => {
        this.copied = null
      }, 1000)
    }
  }
}
</script>
<style lang="sass" scoped>
.copy-img
  width: 15px
.report-description
  word-break: break-word
  word-wrap: break-word
  overflow-wrap: break-word
  padding: 5px 0 !important
.w-54
  width: 54px

:deep(.v-data-table .v-data-table__wrapper table thead tr)
  th:not(.active.desc)
    i
      -webkit-transform: rotate(0deg) translateY(-50%)
      transform: rotate(0deg) translateY(-50%)
  th
    i
      -webkit-transform: rotate(180deg) translateY(50%)
      transform: rotate(180deg) translateY(50%)
</style>
